import React, { useEffect, useState, useRef, useContext } from 'react';
import Router, { useRouter } from 'next/router';
import Link from 'next/link';
import CustomSlider from '../../CustomSlider';
import getTransformedImageURLs from '../../../helpers/getTransformedImageURLs';
import { isNewHomePageApplicable } from '../../../constants/feature';
import { useDispatch } from 'react-redux';
import { setSearchTabs } from '../../../redux/slices/userSlice';
const isMobileDevice = () => window.innerWidth <= 1024;
const CategoryMenu = ({ className, fullCategoryList }) => {
  const router = useRouter();
  const [locationHref, setLocationHref] = useState('');
  const {
    route,
    query: { tab }
  } = router;
  const categoryRef = useRef(null);
  const dispatchRedux = useDispatch()
  const currentSlug = '';

  useEffect(() => {
    setLocationHref(`${window.location.pathname}`);
    let scrollToId = null;
    if (route === '/') {
      scrollToId = 'category-menu-home';
    } else if (currentSlug) {
      scrollToId = `category-menu-${currentSlug}`;
    }
    if (scrollToId) {
    }
  }, [currentSlug, route]);

  const handleNavigate = link => {
    const url = new URL(link);
    return `${url.pathname}${tab && tab.length ? `?tab=${tab}` : ''}`;
  };
  const debounceTimeout = useRef(null);

  const [isVisible, setIsVisible] = useState(null); // Track visibility state

  useEffect(() => {
    const currentRef = categoryRef.current;

    if (!currentRef) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {

        clearTimeout(debounceTimeout.current);

        debounceTimeout.current = setTimeout(() => {
          const newVisibility = entry.intersectionRatio > 0;

          if (isMobileDevice()) {
            if (newVisibility !== isVisible) { // Only update if visibility state changes
              setIsVisible(newVisibility);
              if (!newVisibility) {
                dispatchRedux(setSearchTabs(true));
              } else {
                dispatchRedux(setSearchTabs(false));
              }
            }
          }
        }, 500); // Adjust the debounce delay as needed (e.g., 500ms)
      },
      {
        root: null,
        rootMargin: '20%', // Add a more substantial buffer (20% viewport height)
        threshold: 0 // Trigger as soon as any part of the element is visible
      }
    );

    observer.observe(currentRef);

    return () => {
      clearTimeout(debounceTimeout.current);
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [categoryRef.current, isVisible, dispatchRedux]);

  return (
    <div
      className={`${isNewHomePageApplicable ? 'default-category-section container' : 'default-category-menu mb-lg-3'
        } mb-0 ${className}`}
    >
      <div ref={categoryRef} className="gc-inner">
        <CustomSlider className={`category-item${isNewHomePageApplicable ? 's' : ''}`}>
          {fullCategoryList?.length
            ? fullCategoryList.map(({ name, logo_url: logoUrl, link }) => {
              return (
                <div
                  key={name}
                  id={`category-menu-${name}`}
                  className={`${isNewHomePageApplicable ? 'category-block' : 'd-item'} ${new URL(link).pathname === locationHref ? 'c-selected' : ''
                    }`}
                >
                  <Link prefetch={false} href={`${handleNavigate(link)}`}>
                    <a data-testid="#shop-category-links" className="cursor-pointer no-text-decoration">
                      <div
                        className={`${isNewHomePageApplicable
                          ? 'category-icon-wrap d-flex align-items-center justify-content-center'
                          : 'outer-circle'
                          }`}
                      >
                        <picture>
                          <source
                            srcSet={getTransformedImageURLs(logoUrl, 'webp').srcExtraSmall.replace(
                              'https://grassdoor-public-data-pre-live.s3.us-west-2.amazonaws.com/fit-in/150x150/filters:format(webp)',
                              'https://grassdoor-public-data-pre-live.s3.us-west-2.amazonaws.com'
                            )}
                            type="image/webp"
                          />
                          <img
                            src={getTransformedImageURLs(logoUrl, 'png').srcExtraSmall.replace(
                              'https://grassdoor-public-data-pre-live.s3.us-west-2.amazonaws.com/fit-in/150x150/filters:format(png)',
                              'https://grassdoor-public-data-pre-live.s3.us-west-2.amazonaws.com'
                            )}
                            alt={name}
                            className="img-fluid"
                          />
                        </picture>
                      </div>
                      {isNewHomePageApplicable ? (
                        <div className="category-name">
                          <h4 className="mb-0 fs-12 semibold text-center">{name}</h4>
                        </div>
                      ) : (
                        <div className="figcaption">{name}</div>
                      )}

                    </a>
                  </Link>
                </div>
              );
            })
            : null}
        </CustomSlider>
      </div>
    </div>
  );
};

export default CategoryMenu;
