import React, { useContext, useEffect, useState } from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';
import Link from 'next/link';
import { useRouter } from 'next/router';
import appContext from '../../../Context/appContext';
import ActiveLink from '../../ActiveLink';
import notificationTypes from '../../../constants/modalNotificationConst';
import { useDeliveryDetails, useZipCodeStatus } from '../../../hooks/app';
import CustomSlider from '../../CustomSlider';
import { isWishlistApplicable } from '../../../constants/feature';
import CartProductsWidget from '../../CartProductsWidget';
import { SCHEDULE_TYPE_ASAP, SCHEDULE_TYPE_FUTURE } from '../../../constants/scheduleType';
import { SCHEDULE } from '../../../constants/deliveryType';
import getRouterPath from '../../../helpers/getRouterPath';
import appConfig from '../../../appConfig';
import HappyHours from '../../HappyHours';
import UserDropdown from '../../UserDropdown';
import CartWidget from '../../CartWidget';
import useSegment from '../../../hooks/segment';
import storageService from '../../../services/storageService';
import { useDispatch, useSelector } from 'react-redux';
import { pushNotification } from '../../../redux/slices/modalSlice';
import { toggleLoginDrawer, toggleSidebar, toggleUserDropdown } from '../../../redux/slices/userSlice';
import { setAllCartProductsWidget } from '../../../redux/slices/cartSlice';

export default function SearchTabs({ fullCategoryList, enabled, defaultSearchTab, children, backPath, initialLocationAddress }) {
  const router = useRouter();
  const {
    route,
    query: { tab },
    pathname
  } = router;
  const [locationHref, setLocationHref] = useState('');

  const { trackEvent } = useSegment();
  const {
    data: { asapEnabled = true, happyHours }
  } = useDeliveryDetails(false, true);
  const zoneName = storageService.getZoneLocalStorage();
  const { is_enable: is_happy_hours_enable, end_time } = happyHours || {};
  const { isLoggedIn, showUserDropdown, couponData, initialHistoryLength } = useSelector(state => state.user)
  const {
    cartWidgetProduct,
    cartData: { cart_items: cartItems = [], cart_item_count: cartItemCount = 0 } = {}
  } = useSelector(state => state.cart) || {}
  const dispatchRedux = useDispatch()

  const [clickUnmount, setClickUnmount] = useState(false);
  const [showAllCartProductsWidget, setShowAllCartProductsWidget] = useState(false);
  const {
    data: { validZipcode }
  } = useZipCodeStatus(initialLocationAddress);

  const { discount_code: discountCode } = couponData;
  const currentSlug = '';
  const showWishlistIcon = pathname !== '/login' && pathname !== '/checkout';

  useEffect(() => {
    setLocationHref(`${window.location.pathname}`);
  }, [currentSlug, route]);

  function handleSideMenuClick() {
    // dispatch({ type: 'toggleSidebar' });
    dispatchRedux(toggleSidebar())
  }
  function toggleCartWidget() {
    setShowAllCartProductsWidget(true);
    // dispatch({ type: 'setAllCartProductsWidget', payload: true });
    dispatchRedux(setAllCartProductsWidget(true))
  }

  function renderCartItemCount() {
    return (
      <ActiveLink
        href=""
        onClick={toggleCartWidget}
        className="cursor-pointer d-flex align-items-center cart-btn-info"
        otherAttributes={{ 'data-testid': '#header_cart_btn' }}
      >
        <span className="icon icon-cartdark-icon vertical-align-middle relative">
          {cartItemCount > 0 ? (
            <div className="item-add-count d-flex align-items-center justify-content-center">
              <span className="bold fs-12">{cartItemCount}</span>
            </div>
          ) : null}
        </span>
      </ActiveLink>
    );
  }
  function renderUserInfo() {
    return (
      <ActiveLink
        className="cursor-pointer user-info-btn d-flex align-items-center"
        onClick={() => {
          // dispatch({ type: isLoggedIn ? 'toggleUserDropdown' : 'toggleLoginDrawer' });
          dispatchRedux(isLoggedIn ? toggleUserDropdown() : toggleLoginDrawer())
          // alert()
        }}
      >
        <span className="icon icon-account-h vertical-align-middle" />
      </ActiveLink>
    );
  }
  function renderWishlistIcon() {
    if (isWishlistApplicable && showWishlistIcon) {
      return (
        <a href="/wishlist/favorites">
          <button type="button" className="btn heart-btn">
            <i className="icon icon-heart-outline " />
          </button>
        </a>
      );
    }
  }
  function openSearchPopup() {
    router.push('/search');
  }
  const handleNavigate = link => {
    const url = new URL(link);
    return `${url.pathname}${tab && tab.length ? `?tab=${tab}` : ''}`;
  };

  function goToCart() {
    if (validZipcode) {
      // Router.push('/cart');
      let deliveryType = SCHEDULE_TYPE_ASAP;
      const page = getRouterPath(pathname);
      if (page === 'scheduledshop' || tab == SCHEDULE || !asapEnabled) {
        deliveryType = SCHEDULE_TYPE_FUTURE;
      }
      // if (page === 'brands' && productType !== null) {
      //   deliveryType = productType;
      // }
      router.push({
        pathname: '/checkout',
        query: { deliveryType }
      });
    } else {
      dispatchRedux(pushNotification({ type: notificationTypes.deliverAddressPopup }))
    }
  }
  function goBack() {
    if (window.history.length > initialHistoryLength) {
      router.back();
    } else {
      router.push('/');
    }
  }
  function exitCartWidget() {
    const origin = window.location.origin;
    let productDetails = {};
    cartItems
      .filter(cartItem =>
        cartItem.quantity > 0 && cartItem.bundle_id
          ? cartItem.bundle_id == cartWidgetProduct
          : cartItem.product_id == cartWidgetProduct)
      .map(cartItem => (productDetails = cartItem));
    if (!clickUnmount) {
      trackEvent('Timed Out', {
        event_category: 'Add to Cart Popup',
        coupon: discountCode || '',
        currency: 'usd',
        name: productDetails.product_name || productDetails.name || productDetails.bundle_name,
        original_price: productDetails.price_without_deal || productDetails.price,
        position: '1',
        product_id: productDetails.master_product_id,
        quantity: productDetails.quantity,
        sku: productDetails.bundle_id ? productDetails.bundle_id : productDetails.master_product_id,
        variant:
          productDetails.category_weight && productDetails.category_unit
            ? `${productDetails.category_weight} ${productDetails.category_unit}`
            : '',
        url: `${origin}/product/${productDetails.slug}`,
        zone_name: zoneName
      });
    } else {
      setClickUnmount(false);
    }
  }
  return (
    // <CSSTransition in={enabled} mountOnEnter timeout={300} classNames="search-tabs-control" unmountOnExit>
    enabled ? <>
      <div className="search-tabs d-lg-none">
        <div className="wrapper">
          <div className="search-tabs-header">
            {backPath ? (
              <a className="btn left-btn" test-id="#icon-back" onClick={goBack}>
                <span className="icon-back" />
              </a>
            ) : (
              <button type="button" className="btn left-btn" onClick={handleSideMenuClick} test-id="#hamburger_icon">
                <span className="icon-hamburger icon-ham-menu" />
              </button>
            )}
            <div onClick={openSearchPopup} className="search-section">
              {/* <p className="w-100 d-flex align-items-center mb-0">Search {appConfig.APP_NAME}</p> */}
              <p className="w-100 d-flex align-items-center mb-0">Search</p>
              <i className="icon-search" />
            </div>
            <div className="search-action-btns">
              {renderWishlistIcon()}
              {renderUserInfo()}
              {renderCartItemCount()}
            </div>
          </div>
          <div className="search-tabs-category">
            <CustomSlider className="category-items">
              {fullCategoryList && fullCategoryList.length
                ? fullCategoryList.map(({ name, link }) => {
                  return (
                    // <div key={name} id={`category-menu-${name}`} className={`d-item  ${link === locationHref ? 'c-selected' : ''}`}>
                    <div
                      key={name}
                      id={`category-menu-${name}`}
                      className={`category-block ${new URL(link).pathname === locationHref ? 'c-selected' : ''}`}
                    >
                      <Link prefetch={false} href={`${handleNavigate(link)}`}>
                        <a className="cursor-pointer no-text-decoration">
                          <div className="category-icon-wrap d-flex align-items-center justify-content-center" />
                          <div className="category-name">
                            <h4 className="mb-0 fs-12 semibold text-center">{name}</h4>
                          </div>
                        </a>
                      </Link>
                    </div>
                  );
                })
                : null}
            </CustomSlider>
          </div>
          <div>{children}</div>

          {is_happy_hours_enable ? (
            <div className="timer-sec s-nav-hh d-lg-none">
              <HappyHours className="class" showText={false} />
            </div>
          ) : null}
          <div />
        </div>
        <CSSTransition appear in={showAllCartProductsWidget} mountOnEnter timeout={300} classNames="slideup-cart" unmountOnExit>
          <CartProductsWidget goToCart={goToCart} setShowAllCartProductsWidget={setShowAllCartProductsWidget} />
        </CSSTransition>

        <CSSTransition appear in={showUserDropdown} mountOnEnter timeout={300} classNames="slideup-cart" unmountOnExit>
          <UserDropdown />
        </CSSTransition>
      </div>
    </> : null
  );
}
