import React from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';
import DeliveryTabs from '../DeliveryTabs';
import CategoryMenu from './CategoryMenu';

const MiniTabs = ({
  fullCategoryList,
  setTab,
  currentTab,
  asapProductsCount,
  scheduledProductsCount,
  asapEnabled,
  scheduleEnabled,
  hasAsapProducts = true,
  hasScheduleProducts = true,
  className,
  openInPopup,
  enabled,
  children
}) => {
  return (
    <CSSTransition in={enabled} mountOnEnter timeout={400} classNames="mini-tabs-control" unmountOnExit>
      <div className="mini-tabs d-lg-none">
        <CategoryMenu className="remove-images " fullCategoryList={fullCategoryList} />
        <DeliveryTabs
          currentTab={currentTab}
          asapProductsCount={asapProductsCount}
          scheduledProductsCount={scheduledProductsCount}
          asapEnabled={asapEnabled}
          scheduleEnabled={scheduleEnabled}
          setTab={setTab}
          className="category-page-tabs"
          forMiniTabs
        />
        {children}
      </div>
    </CSSTransition>
  );
};

export default MiniTabs;
